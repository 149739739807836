import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { reload } from 'ionicons/icons';

import './Spinner.css';

export default class Spinner extends Component {

    render() {
        return (
            <div className="spinner page-section padded">
                <IonIcon icon={reload} />
            </div>
        );
    }

}