import React, { Component } from 'react';
import Text from '../../../atoms/Text/Text';

import './Spotlight.css';

export default class Spotlight extends Component {

    render = () => {

        let titleText = this.props.spotlight.get('field_title');

        let link = null;
        const linkObj = this.props.spotlight.get('field_link');
        if(linkObj instanceof Object) {
            link = (
                <div className="link">
                    <a href={linkObj.url}>{linkObj.title}</a>
                </div>
            );

            titleText = <a href={linkObj.url}>{titleText}</a>
        }

        let body = null;
        if(this.props.spotlight.get('field_body') instanceof Object) {
            body = (
                <div className="body">
                    <Text text={this.props.spotlight.get('field_body').processed} />
                </div>
            );
        }


        return (
            <div className="spotlight">
                <h4 className="title">{titleText}</h4>

                {body}

                {link}
            </div>
        );
    }

}