import React, { Component } from 'react';
import { Image as Img } from 'react-bootstrap';

import './Image.css';

export default class Image extends Component {

  render() {
    let caption = null;
    if(this.props.item.get('field_caption')) {
        caption = (
            <div className="caption">
                {this.props.item.get('field_caption')}
            </div>
        );
    }

    let image = <Img src={this.props.item.get('field_image').get('field_media_image').get('links').ajff_org_large_no_crop?.href} />;
    if(this.props.item.get('field_link')?.url) {
        image = (
            <a href={this.props.item.get('field_link').url}>{image}</a>
        );
    }

    let title = null;
    if(this.props.item.get('field_title')) {
        title = (
            <h2>{this.props.item.get('field_title')}</h2>
        );
    }

    return (
        <div className="paragraph image page-section padded container">
            {title}
            {image}
            {caption}
        </div>
    );
  }

}