import React, { Component } from 'react';
import Accordion from '../../../ui/Accordion/Accordion';
import Text from '../../../atoms/Text/Text';
import { IonIcon } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';

import './AccordionList.css';

export default class AccordionList extends Component {

    render = () => {
        const expandedIcon = <IonIcon icon={removeOutline} />;
        const collapsedIcon = <IonIcon icon={addOutline} />;
        let items = [];
        // Using the negative to simplify repeated logic.  Goal is to open first item and nothing more.
        let collapsed = !(this.props.openFirst);
        for (const item of this.props.items) {
            let title = null;
            let body = null;
            if (item.get('field_title')) {
                title = item.get('field_title');
            }

            if (item.get('field_body')) {
                body = (
                    <Text text={item.get('field_body').processed}/>
                );
            }
            items.push(<Accordion collapsed={collapsed}
                                  collapsedText={title}
                                  expandedText={title}
                                  collapsedIcon={collapsedIcon}
                                  expandedIcon={expandedIcon}>
                {body}
            </Accordion>);
            // Only show the first item as opened.
            if (!collapsed) {
                collapsed = true;
            }
        }

        let headline = null;
        if(this.props.headline) {
            headline = (
                <h2>{this.props.headline}</h2>
            );
        }

        return (
            <div className="accordion-list container page-section padded">
                {headline}
                {items}
            </div>
        );
    }

}