import React, { Component } from 'react';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import Include from '../../../../drupal/Include';
import ItemCard from '../ItemList/ItemCard/ItemCard';
import ItemMediaCard from "../ItemList/ItemMediaCard/ItemMediaCard";
import InfiniteScroll from 'react-infinite-scroller';
import PressKitCard from '../../media/PressKit/PressKitCard/PressKitCard';
import Spinner from '../../../ui/Spinner/Spinner';

import './View.css';

export default class View extends Component {

    state = {
        next: null,
        rows: [],
        params: {},
        pager: false,
        hasMore: true,
        last: null,
    };

    render = () => {

        const bundleParts = this.props.settings.bundles.split('--');
        const entityType = bundleParts[0];
        const bundle = bundleParts[1];

        let items = [];
        for(const row of this.state.rows) {
            if (entityType === 'media') {
                switch(bundle) {
                    case 'press_kit':
                        items.push(<PressKitCard entity={row} />);
                        break;
                    default:
                        items.push(<ItemMediaCard entity={row} title={row.get('name')} />);
                }
            } else {
                let imageUrl = null;
                let body = row.get('field_teaser');
                let media = null;
                let suppressLink = false;
                let url = row.get('field_path');
        
                switch(bundle) {
                    case 'page':
                    case 'festival_series':
                        imageUrl = row.get('field_heroes')[0].get('field_hero_image').get('field_media_image').get('links').ajff_org_small?.href;
                        break;
                    case 'offering':
                        const featureFilm = row.get('field_feature_film');
                        if(featureFilm instanceof Include) {
                            imageUrl = featureFilm.get('field_hero_image').get('field_media_image').get('links').ajff_org_small?.href;
                            body = featureFilm.get('field_teaser');
                            url = featureFilm.get('field_path');
                        } else {
                            imageUrl = row.get('field_hero_image').get('field_media_image').get('links').ajff_org_small?.href;
                        }
                        break;
                    case 'person':
                        body = row.get('field_position');
                        suppressLink = true;   
                    // eslint-disable-next-line
                    case 'article':
                    // eslint-disable-next-line
                    case 'film':
                    // eslint-disable-next-line
                    case 'venue':
                    default:
                        media = row.get('field_hero_image');
                        if (media instanceof Include && media.get('field_media_image')) {
                            imageUrl = media.get('field_media_image').get('links').ajff_org_small?.href;
                        }
                        else {
                            imageUrl = null;
                        }
                }

                items.push(<ItemCard image={imageUrl} title={row.get('title')} body={body} linkUrl={url} linkText="Read more" suppressLink={suppressLink} />);
            }
        }

        let title = null;
        if(this.props.title) {
            title = <h2 className="headline">{this.props.title}</h2>
        }

        return (
            <div className="view container">
                <InfiniteScroll pageStart={0} loadMore={this.load} hasMore={this.state.hasMore} initialLoad={true} loader={<Spinner />}>
                    {title}
                    <ContentGrid small={12} medium={6} large={3}>
                        {items}
                    </ContentGrid>
                </InfiniteScroll>
            </div>
        )
    }

    load = async () => {
        if(this.state.hasMore) {
            
            let response = null;
            const settings = this.props.settings;

            if(this.state.next === null) {
                const bundleParts = settings.bundles.split('--');
                const entityType = bundleParts[0];
                const bundle = bundleParts[1];
                let sort = settings.sort_by;
        
                if (entityType === 'media' && sort ==='title') {
                    if(bundle === 'press_kit') {
                        sort = 'field_film.field_sort_title'
                    } else {
                        sort = 'name';
                    }
                }

                if(entityType === 'node' && (bundle === 'film' || bundle === 'offering') && sort === 'title') {
                    sort = 'field_sort_title';
                }
        
                let params = {
                    'sort[main][path]': sort,
                    'sort[main][direction]': settings.sort_direction
                };

                if(settings.term_uuid) {
                    params['filter[term][path]'] = `${this.props.field}.id`;
                    params['filter[term][value]'] = settings.term_uuid;
                }

                if(entityType === 'node') {
                    params['filter[outlet][path]'] = 'outlets.name';
                    params['filter[outlet][value]'] = 'ajff.org';
                }

                let pageSize = 50;
                if(settings.page_size > 0) {
                    pageSize = settings.page_size;
                }
                params['page[limit]'] = pageSize;
        
                response = await this.props.api.getEntities(entityType, bundle, params);
            } else {
                response = await this.props.api.getResponseFromUrl(this.state.next);
            }

            let next = null;
            let hasMore = false;
            if(settings.pager && response.data.hasOwnProperty('links') && response.data.links.hasOwnProperty('next') && response.data.links.next.hasOwnProperty('href') && response.data.links.next.href != null) {
                hasMore = true;
                next = response.data.links.next?.href;
            }

            const rows = this.state.rows;
            rows.push(...response.data.data);

            this.setState({
                next: next,
                hasMore: hasMore,
                rows: rows
            });
        }
    }

}