import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';

export default class FestivalSeriesDetail extends Component {

    render = () => {


        let hero = null;
        let cdtimer = null;
        let content = [];

        if(this.props.entity instanceof Entity) {
            cdtimer = <Paragraph content={this.props.entity.get('field_countdown_timer')} />;
            if(this.props.entity.get('field_heroes') instanceof Array) {
                hero = <Paragraph content={this.props.entity.get('field_heroes')} drupal={this.props.drupal} />;
            } else {
                hero = (
                <div className="page-title page-section padded container">
                    <h1>{this.props.entity.get('title')}</h1>
                </div>
                );
            }

            for(const paragraph of this.props.entity.get('field_content')) {
                content.push(<Paragraph content={paragraph} api={this.props.api} drupal={this.props.drupal} />);
            }
        }

        return (
            <div className="page">

                <div className="content-wrapper container-fluid full-width">
                    {cdtimer}
                <div className="row">
                    <div className="col">
                    {hero}
                    </div>
                </div>

                <div className="row">
                    <div className="page-content col-12">
                    {content}
                    </div>
                </div>

                </div>
            </div>
        );
    }

}