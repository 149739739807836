import React, { Component } from "react";

import './placeholder.css';

export default class ImageVideoPlaceholder extends Component {

    render = () => {

        return (
            <div className="placeholder">
                <div className="image-video"></div>
            </div>
        )

    }

}