import React, { Component } from "react";
import ContentGrid from "../../../layout/ContentGrid/ContentGrid";
import SingleLineTextPlaceholder from "../../../placeholders/SingleLineTextPlaceholder";
import ItemCardPlaceholder from "./ItemCard/ItemCardPlaceholder";

export default class ItemListPlaceholder extends Component {

    render = () => {

        return (
            <div className="item-list-placeholder page-section padded">
                <SingleLineTextPlaceholder size="h2" />
                <ContentGrid small={12} medium={6} large={3}>
                    <ItemCardPlaceholder />
                    <ItemCardPlaceholder />
                    <ItemCardPlaceholder />
                    <ItemCardPlaceholder />
                </ContentGrid>
            </div>
        );

    }

}