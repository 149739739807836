import React, { Component } from "react";
import SingleLineTextPlaceholder from "../../placeholders/SingleLineTextPlaceholder";
import ButtonPlaceholder from '../../placeholders/ButtonPlaceholder';
import BoxPlaceholder from "../../placeholders/BoxPlaceholder";

export default class SidebarListPlaceholder extends Component {

    render = () => {

        return (
            <div className="screening-list-placeholder page-section padded">
                <SingleLineTextPlaceholder size='h3' />

                <BoxPlaceholder>
                    <SingleLineTextPlaceholder />
                    <SingleLineTextPlaceholder />
                    <ButtonPlaceholder />
                </BoxPlaceholder>

                <BoxPlaceholder>
                    <SingleLineTextPlaceholder />
                    <SingleLineTextPlaceholder />
                    <ButtonPlaceholder />
                </BoxPlaceholder>

                <BoxPlaceholder>
                    <SingleLineTextPlaceholder />
                    <SingleLineTextPlaceholder />
                    <ButtonPlaceholder />
                </BoxPlaceholder>

            </div>
        );

    }

}