import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import RemoteVideo from "../../../media/RemoteVideo/RemoteVideo";
import Include from "../../../../../drupal/Include";
import Modal from "../../../../ui/Modal/Modal";

import '../ItemCard/ItemCard.css';

export default class ItemMediaCard extends Component {

  state = {
    modalOpen: false,
    modalContent: null,
  };


  render = () => {

    let imageInclude = null;
    let mainImage = null;
    let mainImageUrl = null;

    if(this.props.entity.get('field_media_image') instanceof Include && this.props.entity.get('field_media_image').data) {
      imageInclude = this.props.entity.get('field_media_image');
    } else {
      imageInclude = this.props.entity.get('thumbnail');
    }

    if(imageInclude instanceof Include) {
      mainImageUrl = imageInclude.get('links')?.ajff_org_small?.href;
    }

    if(mainImageUrl) {
      mainImage = <Image className="onClick" onClick={this.openModal} src={mainImageUrl} />;
    }

    return (
        <div className="item-card">
          <div className="image" role="button">
            {mainImage}
          </div>
          <h4 className="title">{ this.props.title }</h4>
          {this.state.modalContent}
        </div>
    );
  }

  openModal = () => {
      const modal = (
          <Modal modalType='video' handleClose={this.closeModal}>
            <RemoteVideo url={this.props.entity.get('field_media_oembed_video')} />
          </Modal>
      );

      this.setState({
        modalOpen: true,
        modalContent: modal,
      });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      modalContent: null
    });
  }
}