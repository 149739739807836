import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

import './Modal.css';

export default class Modal extends Component {
    
    render() {

        return (
            <div className="modal-overlay">
                <div className="modal-overlay-header">
                    <div onClick={this.props.handleClose} className="modal-close-trigger">
                        <IonIcon icon={arrowBack} />
                    </div>
                </div>

                <div className={`modal-overlay-content ${this.props.modalType}`}>
                    { this.props.children }
                </div>

            </div>
        );
    }
}