import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { pricetag, barbell, home, bed, locationOutline, restaurant, subway, car, airplane, card, bicycle, cart } from 'ionicons/icons';
import MapPopup from '../MapPopup/MapPopup';

import './LocationPin.css';

export default class LocationPin extends Component {

    state = {
        expanded: false
    };
    
    render() {
        let icon = locationOutline;
        let classes = "location-pin";

        switch (this.props.type) {
            case 'airport':
                icon = airplane;
                classes += " secondary";
                break;
            case 'bank':
                icon = card;
                classes += " secondary";
                break;
            case 'fitness':
                icon = barbell;
                classes += " secondary";
                break;
            case 'grocery':
                icon = cart;
                classes += " secondary";
                break;
            case 'hotel':
                icon = bed;
                classes += " secondary";
                break;
            case 'residential':
                icon = home;
                classes += " secondary";
                break;
            case 'recreational':
                icon = bicycle;
                classes += " secondary";
                break;
            case 'retail':
                icon = pricetag;
                classes += " secondary";
                break;
            case 'transit':
                icon = subway;
                classes += " secondary";
                break;
            case 'parking':
                icon = car;
                classes += " secondary";
                break;
            case 'restaurant':
                icon = restaurant;
                classes += " secondary";
                break;
            default:
                icon = locationOutline;
        }

        let label = (
            <div className="pin-text">{this.props.text}</div>
        );

        if(this.props.hideLabel) {
            label = null;
        }

        let popupContent = null;

        if(this.props.popupContent && this.state.expanded) {
            popupContent = <MapPopup handleClose={this.close} content={this.props.popupContent} />
        } 

        return (
            <div className={classes}>
                <IonIcon onClick={this.open} icon={icon} />
                {popupContent}
                {label}
            </div>
        );
    }

    close = () => {
        this.setState({
            expanded: false
        });
    }

    open = () => {
        if(this.props.popupContent) {
            this.setState({
                expanded: true
            });
        }
    }
}