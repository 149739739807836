import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import Meta from '../../components/atoms/Meta/Meta';

export default class Page extends Component {

  render() {

    let hero = null;
    let title = null;
    let cdtimer = null;
    let content = [];

    if(this.props.entity instanceof Entity) {
      cdtimer = <Paragraph content={this.props.entity.get('field_countdown_timer')} />;
      if(this.props.entity.get('field_heroes') instanceof Array) {
        hero = (
            <div className="row">
              <div className="col">
                <Paragraph content={this.props.entity.get('field_heroes')} drupal={this.props.drupal} />
              </div>
            </div>
        );
      }
      if (!this.props.entity.get('field_hide_title')){
        title = (
            <div className="row">
              <div className="col">
                <div className="page-title page-section padded container">
                  <h1>{this.props.entity.get('title')}</h1>
                </div>
              </div>
            </div>
        );
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        content.push(<Paragraph content={paragraph} api={this.props.api} drupal={this.props.drupal} />);
      }
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
      <div className="page">
        {meta}

        <div className="content-wrapper container-fluid full-width">
          {cdtimer}
          {title}
          {hero}

          <div className="row">
            <div className="page-content col-12">
              {content}
            </div>
          </div>

        </div>
      </div>
    );
  }

}