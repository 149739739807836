import React, { Component } from 'react';
import LibSynEmbed from '../../media/LibSynEmbed/LibSynEmbed';

export default class LibSyn extends Component {

    render = () => {
        return (
            <div className="paragraph libsyn page-section padded container">
                <LibSynEmbed libsynId={this.props.item.get('field_libsyn_player').get('field_media_libsyn')} />
            </div>
        );
    }

}