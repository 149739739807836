export default class SiteInfo {
    static UUIDS = {
        OUTLET: '09c1bc05-688b-4ad4-b672-7ac694a260c3'
    }

    static getOutlet = () => {
        return SiteInfo.UUIDS.OUTLET;
    }

}
