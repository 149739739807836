import { Component } from 'react';
import View from '../View/View';
import Button from '../../../ui/Button/Button';
import Modal from '../../../ui/Modal/Modal';

import './ModalView.css';

export default class ModalView extends Component {

    state = {
        modalOpen: false
    };

    render = () => {

        let modal = null;

        if(this.state.modalOpen) {
            modal = (
                <Modal handleClose={this.toggleModal}>
                    <View field="field_tags" settings={this.props.paragraph.get('field_view_settings')} api={this.props.api} />
                </Modal>
            );
        }

        return (
            <div className="modal-view container page-section">
                <Button text={this.props.paragraph.get('field_title')} onClick={this.toggleModal} />

                {modal}
            </div>
        );

    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

}