import React, { Component } from 'react';
import MenuBranch from '../MenuBranch/MenuBranch';

export default class Menu extends Component {

    render() {
        // An array to hold our rendered links
        let branches = [];
        // Get the correct menu using the callback
        const items = this.props.menu(this.props.menuName);

        // Set our maxDepth. 0 is infinite depth. Default is 0.
        let maxDepth = 0;
        if(this.props.depth) {
            maxDepth = this.props.depth;
        }

        // Set our maxItems. This only affects top level links. If not set, show them all.
        let maxItems = items.length;
        if(this.props.maxItems && this.props.maxItems <= items.length) {
            maxItems = this.props.maxItems;
        }

        const options = {
            maxDepth: maxDepth,
            collapsible: this.props.collapsible,
            expandTriggerCollapsed: this.props.expandTriggerCollapsed,
            expandTriggerExpanded: this.props.expandTriggerExpanded
        };

        // For each top level link, render it and its children.
        for(let i = 0; i < maxItems; i++) {
            const currentDepth = 1;

            const branch = (
                <MenuBranch branchId={items[i].id} {...options} link={items[i]} depth={currentDepth} />
            );

            branches.push(branch);
        }

        return (
            <div className="menu">
                { branches }
            </div>
        );
    }

}