import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Include from '../../../../drupal/Include';
import FilmCoreInfo from '../../film/FilmCoreInfo/FilmCoreInfo';
import ScreeningList from '../../happenings/ScreeningList/ScreeningList';
import { Link } from 'react-router-dom';
import Text from '../../../atoms/Text/Text';

import './OfferingCard.css';
import AlertBox from '../../../ui/AlertBox/AlertBox';

export default class OfferingCard extends Component {

    render = () => {

        let linkHref = this.props.entity.get('field_path');
        let image = null;
        let imageMedia = null;
        let details = null;
        let specialMessaging = null;

        const featureFilm = this.props.entity.get('field_feature_film');
        if(featureFilm instanceof Include) {
            linkHref = featureFilm.get('field_path');
            imageMedia = featureFilm.get('field_hero_image');
            details = (
                <>
                    <FilmCoreInfo entity={featureFilm} />
                </>
            );

            if(featureFilm.get('field_special_messaging')) {
                specialMessaging = (
                    <AlertBox>
                        <Text text={featureFilm.get('field_special_messaging').processed} />
                    </AlertBox>
                );
            }
        } else {
            imageMedia = this.props.entity.get('field_hero_image');
        }

        if(imageMedia instanceof Include) {
            image = (
                <div className="image">
                    <a href={linkHref}><Image src={imageMedia.get('field_media_image').get('links').ajff_org_small?.href} /></a>
                </div>
            );
        }
        
        return (
            <div className="offering-card row g-0">
                <div className="film-info col-sm-12 col-md-6">
                    {image}

                    <div className="details">
                        <h3><a href={linkHref}>{this.props.entity.get('title')}</a></h3>

                        {details}

                        <Link to={linkHref}>View Details</Link>
                    </div>

                </div>

                <div className="col-sm-12 col-md-6">

                    <ScreeningList api={this.props.api} contentId={this.props.entity.get('id')} isFilm={false} showTitle={false} happenings={this.props.happenings} />
                    {specialMessaging}
                </div>
            </div>
        );

    }

}