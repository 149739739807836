import React, { Component } from 'react';
import Modal from '../../ui/Modal/Modal';
import Menu from '../Menu/Menu';
import { IonIcon } from '@ionic/react';
import { menu, filmOutline, person, cart } from 'ionicons/icons';
import SearchBox from '../../ui/SearchBox/SearchBox';

import './ModalMenu.css';

export default class ModalMenu extends Component {

    constructor(props) {
        super(props);

        const origBodyOverflow = window.getComputedStyle(document.body).overflow;

        this.state = {
            modalOpen: false,
            origBodyOverflow: origBodyOverflow
        };
    }

    render() {
        let modal = null;
        
        if(this.state.modalOpen) {
            modal = this.getModalContent();
        }

        return (
            <div className="modal-menu">
                <div className="modal-menu-trigger" onClick={this.openModal}>
                    <IonIcon icon={menu} />
                </div>

                {modal}
            </div>
        );
    }

    getModalContent = () => {
        const modal = (
            <Modal handleClose={this.closeModal} modalType='menu'>
                <div className="modal-menu-container">

                    <Menu menuName={this.props.menuName} menu={this.props.menu} depth={this.props.depth} maxItems={this.props.maxItems} collapsible={this.props.collapsible} clickToExpand={this.props.clickToExpand} expandTriggerCollapsed={this.props.expandTriggerCollapsed} expandTriggerExpanded={this.props.expandTriggerExpanded} />

                    <SearchBox closeHandler={this.closeModal} />

                    <div className="menu secondary">
                        <div className="item">
                            <a target="_blank" rel="noreferrer" href="https://watch.ajff.org/">
                                <div className="icon"><IonIcon icon={filmOutline} /></div>
                                <div className="label">Virtual Cinema</div>
                            </a>
                        </div>

                        <div className="item">
                            <a href="https://ajff.evenue.net/cgi-bin/ncommerce3/EVExecMacro?linkID=twajff&evm=myac&entry=myaccount.html&url=https%3A%2F%2Fajff.evenue.net%2Fcgi-bin%2Fncommerce3%2FEVExecMacro%3FlinkID%3Dtwajff%26evm%3Dmyac%26entry%3Dticketmarketplace.html">
                                <div className="icon"><IonIcon icon={person} /></div>
                                <div className="label">My Account</div>
                            </a>
                        </div>

                        <div className="item">
                            <a href="https://ajff.evenue.net/cgi-bin/ncommerce3/EVExecMacro?linkID=twajff&evm=ctmp">
                                <div className="icon"><IonIcon icon={cart} /></div>
                                <div className="label">Cart</div>
                            </a>
                        </div>
                    </div>

                </div>
            </Modal>
        );

        return modal;
    }

    openModal = () => {
        this.setState({
            modalOpen: true
        });

        document.body.style.overflow = 'hidden';
    }

    closeModal = () => {
        document.body.style.overflow = this.state.origBodyOverflow;

        this.setState({
            modalOpen: false
        });
    }

}