import React, { Component } from "react";


class ClockSegment extends Component {
  render() {
    return (<div className="clock-segment">
      <span className="segment-value">{this.props.value}</span>
      <span className="segment-label">{this.props.label}</span>
    </div>);
  }
}

class Clock extends Component {
  state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
  componentDidMount() {
    // Set starting time.
    this.getTimeUntil(this.props.deadline);
    // Primary driving behavior.  Update clock every second.
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }
  twoDigitString(num) {
    return num < 10 ? "0" + num : num;
  }

  /**
   * Set state of component based on time provided.
   *
   * @param deadline
   */
  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }

  /**
   * Build clock segments based on granularity.
   *
   * @returns {[]}
   */
  getSegments() {
    // @todo UNUSED.  Need to resolve the return value.  Currently nothing is rendered.

    let allSegments = [];
    // FALL THOURGH intended to catch ALL segments larger than the grains.
    switch (this.props.grains) {
      case 'Seconds':
        allSegments.push(<ClockSegment value={this.twoDigitString(this.state.seconds)} label="SECS" />);
        allSegments.push(<div className="time-divider"> : </div>);
      // eslint-disable-next-line
      case 'Minutes':
        allSegments.push(<ClockSegment value={this.twoDigitString(this.state.minutes)} label="MINS" />);
        allSegments.push(<div className="time-divider"> : </div>);
      // eslint-disable-next-line
      default:
        allSegments.push(<ClockSegment value={this.twoDigitString(this.state.hours)} label="HOURS" />);
        allSegments.push(<div className="time-divider"> : </div>);
        allSegments.push(<ClockSegment value={this.twoDigitString(this.state.days)} label="DAYS" />);
    }
    // Need to invert order to make sense to humans.
    allSegments.reverse();
    return allSegments;
  }

  render() {
    // Class used to animate clock dividers.
    const classTick = (this.state.seconds % 2) ? 'tock' : 'tick';
    return (<div className={`clock ${classTick}`}>
      {this.getSegments()}
    </div>);
  }
}
export default Clock;