import React, { Component } from 'react';
import RemoteVideo from '../../media/RemoteVideo/RemoteVideo';

export default class Video extends Component {

  render() {
    let title = null;
    if(this.props.item.get('field_title')) {
        title = (
            <h2>{this.props.item.get('field_title')}</h2>
        );
    }

    return (
        <div className="paragraph video container page-section padded">
            {title}
            <RemoteVideo url={this.props.item.get('field_video').get('field_media_oembed_video')} />
        </div>
    );
  }

}