import React, { Component } from 'react';
import { default as ElasticCarousel, consts } from 'react-elastic-carousel';
import { IonIcon } from '@ionic/react';
import { chevronForward, chevronBack } from 'ionicons/icons';

import './Carousel.css';

export default class Carousel extends Component {
    
    render() {

        return (
            <div className="carousel">

                <ElasticCarousel renderArrow={this.arrows} {...this.props.sliderSettings}>

                    {this.props.children}

                </ElasticCarousel>

            </div>
        );

    }

    arrows = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? this.arrow('left', onClick, isEdge) : this.arrow('right', onClick, isEdge);

        return (
            pointer
        );
    }

    arrow = (dir, onClick, isEdge) => {
        let classes = "arrow-icon arrow-left";
        let icon = chevronBack;
        if(dir === 'right') {
            classes = "arrow-icon arrow-right";
            icon = chevronForward;
        }

        if(isEdge) {
            classes += " disabled";
        }

        return(
            <div className={classes} role="button" onClick={onClick} disabled={isEdge}><IonIcon icon={icon} /></div>
        )
    }
}