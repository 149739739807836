import React, {Component} from 'react';
import Text from '../../../atoms/Text/Text';
import Hero from '../Hero/Hero';
import ItemList from '../ItemList/ItemList';
import Entity from '../../../../drupal/Entity';
import Include from '../../../../drupal/Include';
import ContentItem from '../ContentItem/ContentItem';
import SpotlightList from '../SpotlightList/SpotlightList';
import GenericItem from '../GenericItem/GenericItem';
import Image from '../Image/Image';
import LibSyn from '../LibSyn/LibSyn';
import SponsorList from '../SponsorList/SponsorList';
import RandomizedFilmList from '../RandomizedFilmList/RandomizedFilmList';
import View from '../View/View';
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import AccordionList from "../AccordionList/AccordionList";
import ItemMediaCard from "../ItemList/ItemMediaCard/ItemMediaCard";
import HeroCarousel from "../HeroCarousel/HeroCarousel";
import Video from '../Video/Video';
import ModalView from '../ModalView/ModalView';
import EmbeddedMedia from '../EmbeddedMedia/EmbeddedMedia';

export default class Paragraph extends Component  {

  build = (item) => {
    if(item instanceof Include) {
      switch (Entity.parseTypeBundle(item.get('type')).bundle) {
        case 'text':
          return <Text key={item.get('id')} text={item.get('field_body').processed} pageSection={true} padded={true} container={true} />;
        case 'hero':
          return <Hero key={item.get('id')} item={item} drupal={this.props.drupal} />;
        case 'item_list':
          return <ItemList key={item.get('id')} headline={item.get('field_headline')} items={item.get('field_items')} link={item.get('field_link')} drupal={this.props.drupal} />;
        case 'content_item':
          return <ContentItem key={item.get('id')} item={item.get('field_content')} />;
        case 'generic_item':
          return <GenericItem key={item.get('id')} item={item} />;
        case 'spotlight_list':
          return <SpotlightList key={item.get('id')} headline={item.get('field_headline')} items={item.get('field_spotlights')} />;
        case 'image':
          return <Image key={item.get('id')} item={item} />;
        case 'libsyn_player':
          return <LibSyn key={item.get('id')} item={item} />;
        case 'sponsor_list':
          return <SponsorList key={item.get('id')} collapsible={item.get('field_collapsible')} collapsed={item.get('field_collapsed')} headline={item.get('field_headline')} sponsors={item.get('field_content')} />;
        case 'accordion_list':
          return <AccordionList key={item.get('id')} openFirst={item.get('field_open_first')} headline={item.get('field_headline')} items={item.get('field_items')} />
        case 'randomized_film_list':
          return <RandomizedFilmList item={item} api={this.props.api} />;
        case 'view':
          return <View title={item.get('field_title')} field="field_tags" settings={item.get('field_view_settings')} api={this.props.api} />;
        case 'modal_view':
          return <ModalView paragraph={item} api={this.props.api} />
        case 'countdown_timer':
          return <CountdownTimer key={item.get('id')}
                                 grains={item.get('field_granularity')}
                                 target={item.get('field_target_date')}
                                 body={item.get('field_body')?.processed}
                                 headline={item.get('field_title')}/>
        case 'video_item':
          const video = item.get('field_video');
          return <ItemMediaCard entity={video} title={video.get('name')} />
        case 'video':
          return <Video item={item} />
        case 'embedded_media':
          return <EmbeddedMedia content={item.get('field_embed_code')} />
        default:
          return null;
      }
    }

    return null;
  }

  buildList = () => {
    // Render the content.
    if (this.props.content !== undefined) {
      if (this.props.content instanceof Array) {
        // Need to handle Hero Array's differently.
        if(this.props.content.length > 0) {
          const firstElement = this.props.content[0];
          const bundle = Entity.parseTypeBundle(firstElement.get('type')).bundle;
          if (bundle === 'hero') {
            return <HeroCarousel items={this.props.content} drupal={this.props.drupal} />
            // return (<Hero key={firstElement.get('id')} item={firstElement} drupal={this.props.drupal} />);
          }
          // Not a hero, so loop through all child content.
          let content = [];
          this.props.content.forEach(element => {
            content.push(this.build(element));
          })
          return content;
        }
      }
      else {
        // Not an array, so just return the one piece of content.
        return this.build(this.props.content);
      }
    }
    // No content, so no soup for you.
    return null;
  }

  render = () => {
    return this.buildList();
  }

}