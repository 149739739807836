import React, { Component } from 'react';
import Body from '../../../atoms/Body/Body';
import Entity from '../../../../drupal/Entity';
import Meta from '../../meta/Meta/Meta';
import FilmCoreInfo from '../FilmCoreInfo/FilmCoreInfo';
import AlertBox from '../../../ui/AlertBox/AlertBox';
import Text from '../../../atoms/Text/Text';

import './FilmDetails.css';

export default class FilmDetails extends Component {

    render = () => {

        let synopsis = null;
        let meta = null;
        if(this.props.entity instanceof Entity) {
            synopsis = <Body text={this.props.entity.get('field_synopsis')} />
            meta = this.getMeta();
        }

        let specialMessaging = null;
        if(this.props.entity.get('field_special_messaging')) {
            specialMessaging = (
                <AlertBox>
                    <Text text={this.props.entity.get('field_special_messaging').processed} />
                </AlertBox>
            );
        }

        return (
            <div className="film-details">
                <h3>About the Film</h3>
                {specialMessaging}
                <div className="film-synopsis">
                    {synopsis}
                </div>

                <div className="film-details">
                    {meta}
                </div>

            </div>
        );
    }

    getMeta = () => {
        return (
            <>
                <FilmCoreInfo entity={this.props.entity} />

                <h4>Additional Details</h4>

                <div className="row">
                    <div className="col">
                        <Meta key='field_subjects' api={ this.props.api } field='field_subjects' isInclude={true} isMulti={true} entity={this.props.entity} label="Subjects" suppressLinks={true} />
                        <Meta key='field_languages' api={ this.props.api } field='field_languages' isInclude={true} isMulti={true} entity={this.props.entity} label="Languages" suppressLinks={true} />
                        <Meta key='field_has_subtitles' field='field_has_subtitles' isInclude={false} isMulti={false} entity={this.props.entity} label="Subtitles" />
                    </div>

                    <div className="col">
                        <Meta key='field_countries' api={ this.props.api } field='field_countries' isInclude={true} isMulti={true} entity={this.props.entity} label="Country" suppressLinks={true} />
                        <Meta key='field_year' api={ this.props.api } field='field_year' isInclude={false} isMulti={false} entity={this.props.entity} label="Year" />
                        <Meta key='field_premiere_status' field='field_premiere_status' isMulti={false} isInclude={true} entity={this.props.entity} label="Premiere Status" suppressLinks={true} />
                        <Meta key='field_website' field='field_website' isInclude={false} isMulti={false} isLink={true} entity={this.props.entity} label="Website" />
                    </div>
                </div>
            </>
        );
    }



}