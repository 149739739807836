import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

import './DateTimeFormated.css';

export default class DateTimeFormated extends Component {
  render() {

    const format = (this.props.format) ? this.props.format : "MM/YYYY";

    const classes = "date-time-formated " + this.props.className;

    return (
        <Moment tz="America/New_York" className={classes} format={format} date={this.props.date} />
    )
  }
}