import React, { Component } from "react";
import MediaThumbnail from "../MediaThumbnail/MediaThumbnail";
import Include from "../../../../drupal/Include";

import './MediaList.css';

export default class MediaList extends Component {

    render = () => {

        let media = [];

        if(this.props.entity.get('field_media') instanceof Array) {
            for(const mediaItem of this.props.entity.get('field_media')) {
                media.push(mediaItem);
            }
        }

        let posterImage = this.props.entity.get('field_poster_image');

        if(posterImage instanceof Include) {
            media.unshift(this.props.entity.get('field_poster_image'));
        }
        let items = [];
        let title = null;
        let hasTrailer = false;

        for(const item of media) {
            let addItem = true;

            if(item.get('type') === 'media--remote_video' && item.get('field_media_type').get('name') === 'Trailer') {
                if(!hasTrailer) {
                    hasTrailer = true;
                    addItem = false;
                }
            }

            if(addItem) {
                items.push(<MediaThumbnail media={item} />);
            }
        }

        if(items.length > 0) {
            title = (
                <h3>Media</h3>
            );
        }

        return (
            <div className="media-list page-section padded">
                {title}
                <div className="items d-flex .flex-wrap">
                    {items}
                </div>
            </div>
        );

    }

}