import React, { Component } from 'react';
import Screening from '../Screening/Screening';
import VirtualScreening from '../VirtualScreening/VirtualScreening';
import SidebarListPlaceholder from '../../sidebar/SidebarListPlaceholder';

export default class ScreeningList extends Component {

    state = {
        screenings: [],
        virtualScreenings: [],
        loading: true
    };

    componentDidMount = async () => {

        let screenings = [];
        let virtualScreenings = [];

        if(this.props.happenings) {
            for(const happening of this.props.happenings) {
                switch(happening.get('type')) {
                    case 'happening--screening':
                        screenings.push(happening);
                        break;
                    case 'happening--virtual_screening':
                        virtualScreenings.push(happening);
                        break;
                    default:
                        break;
                }
            }
        } else {

            let offerings = [
                this.props.contentId
            ];

            if(this.props.isFilm) {
                const offeringParams = {
                    'filter[containsfilm][path]': 'field_films.id',
                    'filter[containsfilm][value]': this.props.contentId
                };

                const offeringResponse = await this.props.api.getEntities('node', 'offering', offeringParams);

                if(offeringResponse?.data?.data instanceof Array) {
                    offerings = [];

                    for(const offering of offeringResponse.data.data) {
                        offerings.push(offering.get('id'));
                    }
                }

            }

            let params = {
                'filter[content][condition][path]': 'field_offering.id',
                'filter[content][condition][operator]': 'IN',
                'sort[datetime][path]': 'field_date_time',
                'sort[datetime][direction]': 'ASC'
            };

            for(let i = 0; i < offerings.length; i++) {
                params[`filter[content][condition][value][${i}]`] = offerings[i];
            }

            const screeningItems = await this.props.api.getEntities('happening', 'screening', params);
            screenings = screeningItems.data.data;

            params['sort[datetime][path]'] = 'field_start_date';

            const virtualScreeningItems = await this.props.api.getEntities('happening', 'virtual_screening', params);
            virtualScreenings = virtualScreeningItems.data.data;
        }

        this.setState({
            screenings: screenings,
            virtualScreenings: virtualScreenings,
            loading: false
        });
    }

    render = () => {

        if(this.state.loading) {
            return (
                <SidebarListPlaceholder />
            );
        }
        
        let screenings = [];
        let virtualScreenings = [];

        for(const screening of this.state.screenings) {
            screenings.push(<Screening key={screening.get('id')} screening={screening} />);
        }

        for(const virtualScreening of this.state.virtualScreenings) {
            virtualScreenings.push(<VirtualScreening key={virtualScreening.get('id')} screening={virtualScreening} />);
        }

        let title = null;
        if(this.props.showTitle && screenings.length > 0) {
            title = (
                <h3>Screenings</h3>
            );
        }

        let classes = null;
        if(this.props.isPageSection) {
            classes = 'page-section padded';
        }

        return (
            <div className={`screening-list ${classes}`}>
                {title}
                {screenings}
                {virtualScreenings}
            </div>
        );
    }

}