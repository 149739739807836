import React, { Component } from 'react';
import SidebarListPlaceholder from '../../../sidebar/SidebarListPlaceholder';
import QA from '../QA/QA';

export default class QAList extends Component {

    state = {
        qas: [],
        loading: true
    };

    componentDidMount = async () => {

        let fieldName = 'field_offering';

        if(this.props.isFilm) {
            fieldName = 'field_feature_film';
        }

        const params = {
            'filter[content][path]': `${fieldName}.id`,
            'filter[content][value]': this.props.contentId,
            'sort[datetime][path]': 'field_start_date',
            'sort[datetime][direction]': 'ASC'
        };

        const items = await this.props.api.getEntities('media', 'q_a', params);

        this.setState({
            qas: items.data.data,
            loading: false
        });
    }

    render = () => {

        if(this.state.loading) {
            return (
                <SidebarListPlaceholder />
            );
        }
        
        let qas = [];

        for(const qa of this.state.qas) {
            qas.push(<QA key={qa.get('id')} qa={qa} />);
        }

        if(qas.length === 0) {
            return null;
        }

        return (
            <div className="qa-list page-section padded">
                <h2>Q&amp;A</h2>
                {qas}
            </div>
        );
    }

}