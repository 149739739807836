import React, { Component } from 'react';
import SidebarItem from '../../../sidebar/SidebarItem/SidebarItem';
import Button from '../../../../ui/Button/Button';
import Include from '../../../../../drupal/Include';
import PersonMini from '../../../person/PersonMini/PersonMini';
import SpeakerList from '../../../person/SpeakerList/SpeakerList';

export default class QA extends Component {

    render = () => {

        let button = null;
        if(new Date(this.props.qa.get('field_start_date')) <= new Date()) {
            button = (
                <Button url={this.props.qa.get('field_media_oembed_video_1')} target="_blank" text='Watch Q&amp;A Panel' />
            );
        }

        let speakers = [];
        let speakerEntities = [];

        const introSpeakers = this.props.qa.get('field_intro_speakers');
        if(introSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(introSpeakers);
        }

        const sponsorIntroSpeakers = this.props.qa.get('field_sponsor_intro_speakers');
        if(sponsorIntroSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(sponsorIntroSpeakers);
        }

        const qaModerators = this.props.qa.get('field_q_a_moderators');
        if(qaModerators instanceof Array) {
            speakerEntities = speakerEntities.concat(qaModerators);
        }

        const qaPanelists = this.props.qa.get('field_q_a_panelists');
        if(qaPanelists instanceof Array) {
            speakerEntities = speakerEntities.concat(qaPanelists);
        }

        for(const speaker of speakerEntities) {
            const media = speaker.get('field_hero_image');
            let speakerImage = null;
            if(media instanceof Include) {
                speakerImage = media.get('field_media_image').get('links').ajff_org_hero?.href;
            }
            speakers.push(<PersonMini name={speaker.get('title')} position={speaker.get('field_position')} image={speakerImage} />);
        }

        let speakerList = null;
        if(speakers.length > 0) {
            speakerList = <SpeakerList speakers={speakers} />;
        }


        return (
            <SidebarItem subtitle={this.props.qa.get('field_release_window_qualifier')} button={button} alternateTheme={true} extra={speakerList} />
        );
    }

}