import React, { Component } from "react";
import TextPlaceholder from "../../../placeholders/TextPlaceholder";
import SingleLineTextPlaceholder from "../../../placeholders/SingleLineTextPlaceholder";
import ImageVideoPlaceholder from "../../../placeholders/ImageVideoPlaceholder";

export default class HeroItemPlaceholder extends Component {

    render = () => {

        return (
            <div className="hero-item-placeholder page-section padded">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-8">
                        <ImageVideoPlaceholder />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <SingleLineTextPlaceholder size="h2" />
                        <TextPlaceholder />
                    </div>
                </div>
            </div>
        );

    }

}