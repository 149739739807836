import React, { Component } from 'react';

export default class Address extends Component {
  render() {

    let line2 = null;
    let addressField = this.props.addressField;
    if (addressField) {
      line2 = (
          <div className="address-line-2">
            {addressField.address_line2}
          </div>
      );
    }
    return (
      <div className="address">
        <div className="address-line-1">
          {addressField.address_line1}
        </div>

        {line2}

        <div className="city-state-zip">
          {addressField.locality}, {addressField.administrative_area} {addressField.postal_code}
        </div>
      </div>
    );
  }


}