import React, { Component } from 'react';
import FilmMini from '../FilmMini/FilmMini';

import './OfferingFilmList.css';

export default class OfferingFilmList extends Component {

    render = () => {

        let films = [];
        for(const film of this.props.offering.get('field_films')) {
            films.push(<FilmMini film={film} />);
        }

        return(
            <div className="offering-film-list">
                {films}
            </div>
        );
    }

}