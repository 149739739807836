import React, { Component } from 'react';
import Menu from '../../menu/Menu/Menu';
import ModalMenu from '../../menu/ModalMenu/ModalMenu';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline, chevronDownOutline, filmOutline, person, cart } from 'ionicons/icons';
import { Image } from 'react-bootstrap';
import SearchBar from '../../content/search/SearchBar/SearchBar';
import Logo from '../../../images/svg/logo.svg';

import './Header.css';

export default class Header extends Component {

    render() {
        return (
            <header>
                <div className="superheader">
                    <div className="container">
                        <div className="d-flex superheader-content">
                            <div className="alerts flex-shrink-1">
                                {this.props.outlet.get('field_alert_text')}
                            </div>

                            <div className="d-md-flex menu flex-grow-1">
                                <div className="item">
                                    <a target="_blank" rel="noreferrer" href="https://watch.ajff.org/"><IonIcon icon={filmOutline} /> Virtual Cinema</a>
                                </div>

                                <div className="item">
                                    <a href="https://ajff.evenue.net/cgi-bin/ncommerce3/EVExecMacro?linkID=twajff&evm=myac&entry=myaccount.html&url=https%3A%2F%2Fajff.evenue.net%2Fcgi-bin%2Fncommerce3%2FEVExecMacro%3FlinkID%3Dtwajff%26evm%3Dmyac%26entry%3Dticketmarketplace.html"><IonIcon icon={person} /> My Account</a>
                                </div>

                                <div className="item">
                                    <a href="https://ajff.evenue.net/cgi-bin/ncommerce3/EVExecMacro?linkID=twajff&evm=ctmp"><IonIcon icon={cart} /> Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="page-header">
                    <div className="container">
                        <div className="d-flex page-header-content">
                            <div className="branding flex-grow-1 flex-lg-shrink-1">
                                <Link to="/"><Image src={Logo} /></Link>
                            </div>

                            <div className="main-menu d-none d-lg-block flex-grow-1">
                                <Menu maxItems={4} depth={1} menuName="ajff-org-main-menu" menu={this.props.menu} />
                            </div>

                            <div className="flex-shrink-1 d-none d-lg-block">
                                <SearchBar />
                            </div>

                            <div className="flex-shrink-1 d-lg-none">
                                <ModalMenu menu={this.props.menu} menuName="ajff-org-main-menu" depth={0} collapsible={true} expandTriggerExpanded={<IonIcon icon={chevronDownOutline} />} expandTriggerCollapsed={<IonIcon icon={chevronForwardOutline} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}