import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import Meta from '../../components/atoms/Meta/Meta';
import View from '../../components/content/paragraphs/View/View';

export default class TaxonomyTermPage extends Component {

  render() {

    let hero = null;
    let title = null;
    let content = [];

    if(this.props.entity instanceof Entity) {
      if(this.props.entity.get('field_heroes') instanceof Array) {
        hero = (
            <div className="row">
              <div className="col">
                <Paragraph content={this.props.entity.get('field_heroes')} drupal={this.props.drupal} />
              </div>
            </div>
        );
      }

      if (!this.props.entity.get('field_hide_title')){
        title = (
            <div className="row">
              <div className="col">
                <div className="page-title page-section padded container">
                  <h1>{this.props.entity.get('name')}</h1>
                </div>
              </div>
            </div>
        );
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        content.push(<Paragraph content={paragraph} api={this.props.api} drupal={this.props.drupal} />);
      }

      let field = null;
      switch(this.props.entity.get('type')) {
        case 'taxonomy_term--genre':
          field = 'field_genre';
          break;
        case 'taxonomy_term--subject':
          field = 'field_subjects';
          break;
        case 'taxonomy_term--language':
          field = 'field_languages';
          break;
        case 'taxonomy_term--country':
          field = 'field_countries';
          break;
        case 'taxonomy_term--premiere_status':
          field = 'field_premiere_status';
          break;
        default:
          field = null;
      }

      const settings = {
        "bundles": "node--film",
        "term": 0,
        "term_uuid": this.props.entity.get('id'),
        "pager": true,
        "page_size": 24,
        "sort_by": "title",
        "sort_direction": "asc"
      }

      content.push(<View field={field} title="Films" settings={settings} api={this.props.api} />);
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
      <div className="taxonomy-term-page">
        {meta}

        <div className="content-wrapper container-fluid full-width">
          {title}
          {hero}

          <div className="row">
            <div className="page-content col-12">
              {content}
            </div>
          </div>

        </div>
      </div>
    );
  }

}