import React, { Component } from 'react';

import './PersonMini.css';

export default class PersonMini extends Component {

    render = () => {
        return (
            <div className="person-mini d-flex">
                <div className="details flex-grow-1">
                    <h5 className="name">
                        {this.props.name}
                    </h5>

                    <div className="position">
                        {this.props.position}
                    </div>
                </div>
            </div>
        );
    }

}