import React, { Component } from "react";

import './placeholder.css';

export default class BoxPlaceholder extends Component {

    render = () => {

        return (
            <div className="placeholder box">
                {this.props.children}
            </div>
        )

    }

}