import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Modal from '../../../ui/Modal/Modal';
import RemoteVideo from "../RemoteVideo/RemoteVideo";

export default class MediaThumbnail extends Component {

    state = {
        modalOpen: false,
        modalContent: null
    };

    render = () => {

        let thumbnail = null;

        switch(this.props.media.get('type')) {
            case 'media--image':
                thumbnail = this.props.media.get('field_media_image').get('links').medium?.href;
                break;
            case 'media--remote_video':
                thumbnail = this.props.media.get('thumbnail').get('links').medium?.href;
                break;
            default:
                thumbnail = null;
        }

        return (
            <div className="media-thumbnail">
                <Image src={thumbnail} onClick={this.openModal} role="button" />

                {this.state.modalContent}
            </div>
        );

    }

    openModal = () => {

        let modalContent = null;
        let modalType = null;
        switch(this.props.media.get('type')) {
            case 'media--image':
                modalContent = <Image src={this.props.media.get('field_media_image').get('links').ajff_org_large_no_crop?.href} />;
                modalType = 'image';
                break;
            case 'media--remote_video':
                modalContent = <RemoteVideo url={this.props.media.get('field_media_oembed_video')} />;
                modalType = 'video';
                break;
            default:
                modalContent = null;
                modalType = null;
        }

        const modal = (
            <Modal modalType={modalType} handleClose={this.closeModal}>
              {modalContent}
            </Modal>
        );
  
        this.setState({
          modalOpen: true,
          modalContent: modal,
        });
    }
  
    closeModal = () => {
      this.setState({
        modalOpen: false,
        modalContent: null
      });
    }
  
}