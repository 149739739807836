import React from 'react';
import Page from '../pages/Page/Page';
import FilmOfferingDetailPage from '../pages/FilmOfferingDetailPage/FilmOfferingDetailPage';
import VenuePage from "../pages/Venue/VenuePage";
import FestivalSeries from '../pages/FestivalSeries/FestivalSeries';
import ArticlePage from "../pages/ArticlePage/ArticlePage";
import TaxonomyTermPage from '../pages/TaxonomyTermPage/TaxonomyTermPage';

export default class ComponentMap {

    static map = [
        {
            entityType: 'node',
            entityBundle: [
                'page'
            ],
            component: <Page />
        },
        {
            entityType: 'node',
            entityBundle: [
                'film',
                'offering'
            ],
            component: <FilmOfferingDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'festival_series'
            ],
            component: <FestivalSeries />
        },
        {
            entityType: 'node',
            entityBundle: [
                'article'
            ],
            component: <ArticlePage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'venue'
            ],
            component: <VenuePage />
        },
        {
            entityType: 'taxonomy_term',
            entityBundle: [
                'country',
                'genre',
                'language',
                'premiere_status',
                'subject'
            ],
            component: <TaxonomyTermPage />
        }
    ];
}
