import React, { Component } from 'react';
import Clock from "./Clock";

import './CountdownTimer.css';

export default class CountdownTimer extends Component {

  render () {
    const headline = this.props.headline;
    return (

      <div className="row countdown-timer" data-target={this.props.target}>
        <div className="col col-12">
          <div className="container">
            <div className="clock-background-wrapper d-md-flex">
              <h2>{ headline }</h2>
              <Clock deadline={this.props.target} grains={this.props.grains} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}