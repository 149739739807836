import React, { Component } from 'react';
import ItemCard from '../ItemList/ItemCard/ItemCard';

export default class GenericItem extends Component {

    render = () => {
        return(
            <div className="generic-item">
                <ItemCard title={this.props.item.get('field_title')} body={this.props.item.get('field_body')?.processed} image={this.props.item.get('field_image').get('field_media_image').get('links').ajff_org_small?.href} linkUrl={this.props.item.get('field_link')?.url} linkText={this.props.item.get('field_link')?.title} />
            </div>
        );
    }

}