import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './SponsorCard.css';

export default class SponsorCard extends Component {

    render = () => {

        let content = (
            <div className="title">
                {this.props.sponsor.get('title')}
            </div>
        );
        
        if(this.props.sponsor.get('field_logo')) {
            content = (
                <Image src={this.props.sponsor.get('field_logo').get('field_media_image').get('links').ajff_org_small_no_crop?.href} />
            );
        }

        if(this.props.sponsor.get('field_website')) {
            content = (
                <a target="_blank" rel="noreferrer" href={this.props.sponsor.get('field_website').url}>
                    {content}
                </a>
            );
        }

        return (
            <div className="node sponsor-card">
                {content}
            </div>
        );
    }

}