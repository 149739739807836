import React, { Component } from 'react';
import SearchBox from '../../../ui/SearchBox/SearchBox';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';

import './SearchBar.css';

export default class SearchBar extends Component {

    state = {
        expanded: false
    };

    render = () => {

        let searchBox = null;
        if(this.state.expanded) {
            searchBox = <SearchBox closeHandler={this.toggleSearchBox} />;
        }

        return (
            <div className="search-bar">
                <div className="search-trigger" onClick={this.toggleSearchBox}>
                    <IonIcon icon={search} />
                </div>

                {searchBox}
            </div>
        )
    }

    toggleSearchBox = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }
}