import React, { Component } from 'react';

import './Accordion.css';

export default class Accordion extends Component {

    state = {
        collapsed: this.props.collapsed
    };

    render = () => {

        let icon = this.props.expandedIcon;
        let text = this.props.expandedText;
        let collapsedClass = "expanded";
        if(this.state.collapsed) {
            icon = this.props.collapsedIcon;
            text = this.props.collapsedText;
            collapsedClass = "collapsed";
        }

        return (
            <div className="accordion">
                <div className="trigger" role="button" onClick={this.handleClick}>{icon} {text}</div>
                <div className={`accordion-content ${collapsedClass}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    handleClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

}