import React, { Component } from "react";
import FilterOption from './FilterOption';

export default class Filter extends Component {

    state = {
        selectedOption: null,
    };

    render = () => {

        let input = null;

        if(this.props.type === 'boolean') {
            input = (
                <div className="checkbox">
                    {this.props.filterLabel} <input type="checkbox" name={this.props.filterName} checked={this.props.value} onChange={this.props.handleChange} />
                </div>
            );
        } else {
            let defaultSelected  = false;
            if(this.props.value === null) {
                defaultSelected = true;
            }
    
            let options = [
                <FilterOption label={this.props.filterLabel} value="null" selected={defaultSelected} />
            ];
    
            for(const item of this.props.items) {
                let selected = false;
                if(item.id === this.props.value) {
                    selected = true;
                }
                options.push(<FilterOption key={item.id} label={item.label} value={item.id} selected={selected} />);
            }

            input = (
                <select name={this.props.filterName} label={this.props.filterLabel} onChange={this.props.handleChange}>
                    {options}
                </select>
            );
        }


        return (
            <div className={`filter ${this.props.filterClasses}`}>
                {input}
            </div>
        );

    }

    getOptions = async (params) => {
        let options = [];

        let next = '';
        let hasNext = true;

        let response = await this.props.api.getEntities(this.props.optionsEntityType, this.props.optionsBundle, params);

        options = options.concat(response.data.data);

        if(response.data?.links?.next?.href) {
            next = response.data.links.next.href;
            
            while(hasNext) {
                response = await this.props.api.getResponseFromUrl(next);

                if(response.data?.links?.next?.href) {
                    next = response.data.links.next.href;
                } else {
                    hasNext = false;
                }

                options = options.concat(response.data.data);
            }
        }

        return options;

    }

}