import React, { Component } from 'react';
import Hero from "../Hero/Hero";
import Carousel from "../../../ui/Carousel/Carousel";

export default class HeroCarousel extends Component {
  render() {
    // @todo Move this back to Paragraph.  We should be handling single images.
    // When there is only one, return a single hero.
    if (this.props.items.length === 1) {
      return (
          <Hero key={this.props.items[0].get('id')} item={this.props.items[0]} drupal={this.props.drupal} />
      )
    }

    // Build for a Carousel.
    const sliderSettings = {
      itemsToShow: 1,
      pagination: true,
      showArrows: false,
      enableAutoPlay: true,
      itemPadding: [0,10,0,10]
    };

    let sliders = [];
    this.props.items.forEach(item => {
      sliders.push(<Hero key={item.get('id')} item={item} drupal={this.props.drupal} />);
    })
    return (
        <Carousel sliderSettings={sliderSettings} children={sliders} />
    );
  }
}