import React, { Component } from 'react';

import './SelectorItem.css';

export default class SelectorItem extends Component {

    render() {
        return (
            <div className="selector-item">
                <a href={this.props.item.url}>{this.props.item.title}</a>
            </div>
        );
    }

}