import React, { Component } from 'react';

import './Text.css';
import {Helmet} from "react-helmet";

export default class Text extends Component {

  render() {

    let helmet = null;
    let html = null;

    let rawHtml = this.props.text;

    if(typeof rawHtml !== 'string') {
      return null;
    }
    
    // Check for scripts in html.
    const scriptPos = rawHtml.indexOf('</script>');
    if (scriptPos === -1 ) {
      // No scripts.  Just HTML.
      html = {
        __html: rawHtml
      };
    }
    else {
      // Beakup by slicing at the ending tag.
      let fragments = rawHtml.split('</script>');
      let cleanHtml = [];
      let scripts = [];
      let subFragment = null;
      const rexLead = /<script .*>/;

      // loop through fragments.
      for (let index = 0; index < fragments.length; index++) {
        if (fragments[index].indexOf('<script') === -1) {
          // No scripting in the fragment.
          cleanHtml.push(fragments[index]);
        }
        else {
          // We have script and maybe html.
          subFragment = fragments[index].split (rexLead);
          // Only HTML will be leading the head tag.
          if (subFragment.length > 1) {
            cleanHtml.push(subFragment.shift())
          }
          // Magic part to build element from the remaining text.
          scripts.push(
              <script>
                {subFragment.shift()}
              </script>
          );
        }
      }
      helmet = (
          <Helmet>
            {scripts}
          </Helmet>
      );
      // Set remaining html.
      html = {
        __html: cleanHtml.join('')
      };
    }

    let classes = [];
    if(this.props.pageSection) {
      classes.push('page-section');
    }

    if(this.props.padded) {
      classes.push('padded');
    }

    if(this.props.container) {
      classes.push('container');
    }

    return (
      <>
        {helmet}
        <div className={`text paragraph editor-body ${classes.join(' ')}`} dangerouslySetInnerHTML={html} />
      </>
    );
  }

}
