import React, { Component } from 'react';
import Text from '../../../../atoms/Text/Text';
import { Image } from 'react-bootstrap';
import ItemCardPlaceholder from './ItemCardPlaceholder';

import './ItemCard.css';

export default class ItemCard extends Component {

    render = () => {

      if(this.props.image === null && this.props.title === null && this.props.linkUrl === null) {
        return <ItemCardPlaceholder />;
      }

      let image = null;
      if (this.props.image) {
        if(this.props.suppressLink) {
          image = (
            <div className="image">
                <Image src={this.props.image} />
            </div>
          );

        } else {
          image = (
            <div className="image">
              <a href={this.props.linkUrl}>
                <Image src={this.props.image} />
              </a>
            </div>
          );
        }
      }

      let link = null;
      if(!this.props.suppressLink) {
        link = (
          <div className="link">
            <a href={this.props.linkUrl}>{this.props.linkText}</a>
          </div>
        );
      }

      let title = this.props.title;
      if(!this.props.suppressLink) {
        title = (
          <a href={this.props.linkUrl}>{title}</a>
        );
      }

      return (
          <div className="item-card">
            {image}
              <h4 className="title">{title}</h4>

              <div className="body">
                  <Text text={this.props.body} />
              </div>

              {link}
          </div>
      );
    }

}