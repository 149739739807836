import React, { Component } from "react";
import SingleLineTextPlaceholder from "../../components/placeholders/SingleLineTextPlaceholder";
import OfferingCardPlaceholder from "../../components/content/offering/OfferingCard/OfferingCardPlaceholder";


export default class SuperShopperPagePlaceholder extends Component {

    render = () => {

        return (
            <div className="super-shopper-page container">
                <SingleLineTextPlaceholder size="h1" />

                <SingleLineTextPlaceholder size="h1" />

                <SingleLineTextPlaceholder size="h3" />
                
                <OfferingCardPlaceholder />

                <OfferingCardPlaceholder />

            </div>
        );

    }

}