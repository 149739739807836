import React, { Component } from 'react';
import TextPlaceholder from '../../../../placeholders/TextPlaceholder';
import ImageVideoPlaceholder from '../../../../placeholders/ImageVideoPlaceholder';
import SingleLineTextPlaceholder from '../../../../placeholders/SingleLineTextPlaceholder';

export default class ItemCardPlaceholder extends Component {

    render = () => {
        return (
            <div className="item-card-placeholder">
                <ImageVideoPlaceholder />
                <SingleLineTextPlaceholder size="h4" />
                <TextPlaceholder />
            </div>
        );
    }

}