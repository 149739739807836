import React, { Component } from "react";
import Accordion from "../../../ui/Accordion/Accordion";
import { IonIcon } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';

import './SpeakerList.css';

export default class SpeakerList extends Component {

    render = () => {

        const expandedIcon = <IonIcon icon={removeOutline} />;
        const collapsedIcon = <IonIcon icon={addOutline} />;

        return (
            <div className="speaker-list">
                <Accordion collapsed={true} role="button" expandedIcon={expandedIcon} collapsedIcon={collapsedIcon} expandedText="Hide Guest Speakers" collapsedText="View Guest Speakers">
                    {this.props.speakers}
                </Accordion>
            </div>
        );
    }

}