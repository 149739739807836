import React, { Component } from 'react';
import Body from '../../../atoms/Body/Body';
import Entity from '../../../../drupal/Entity';
import OfferingFilmList from '../../film/OfferingFilmList/OfferingFilmList';

export default class OfferingDetails extends Component {

    render = () => {

        let synopsis = null;
        if(this.props.entity instanceof Entity) {
            synopsis = <Body text={this.props.entity.get('field_synopsis')} />
        }

        return(
            <div className="offering-details">
                <h3>About this Program</h3>
                <div className="offering-synopsis">
                    {synopsis}
                </div>

                <OfferingFilmList offering={this.props.entity} />
            </div>
        );
    }
}