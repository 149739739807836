import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import { withRouter } from 'react-router-dom';

import './SearchBox.css';

class SearchBox extends Component {

    render() {
        return (
            <div className="search-box">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Control name="keywords" type="text" placeholder='Search our site'/>
                    <Button type="submit">
                        <IonIcon icon={search} />
                    </Button>
                </Form>
            </div>
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.closeHandler();

        this.props.history.push(`/search?keywords=${e.target.children.keywords.value}`);
    }

}

export default withRouter(SearchBox);