import React, { Component } from 'react';

import './Button.css';

export default class Button extends Component {

    render() {
        return (
            <a className={`button ${this.props.buttonClasses}`} target={this.props.target} href={this.props.url} onClick={this.props.onClick} role="button">{this.props.text}</a>
        );
    }

}
