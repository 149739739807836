import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { logoInstagram, logoFacebook, logoTwitter, logoYoutube } from 'ionicons/icons';
import SubscribeForm from '../../ui/SubscribeForm/SubscribeForm';
import Menu from '../../menu/Menu/Menu';
import { Image } from 'react-bootstrap';
import AJCLogo from '../../../images/svg/ajc_logo.svg';

import './Footer.css';

export default class Footer extends Component {

    render() {

        const year = new Date().getFullYear();

        return (
            <footer>
                <div className="pre-footer">
                    <div className="container d-flex">
                        <div className="row flex-grow-1">
                            <h2 className="col-sm-12 col-md-3">Stay Connected</h2>
                            <div className="subscribe col-sm-12 col-md-6">
                                <SubscribeForm />
                            </div>
                            <div className="follow col-sm-12 col-md-3">
                                <h4>Follow on Social</h4>
                                <div className="social-links d-flex">
                                    <a className="flex-grow-1" href="https://www.instagram.com/atljewishfilm/" target="_blank" rel="noreferrer"><IonIcon icon={logoInstagram} /></a>
                                    <a className="flex-grow-1" href="https://www.facebook.com/atljewishfilm" target="_blank" rel="noreferrer"><IonIcon icon={logoFacebook} /></a>
                                    <a className="flex-grow-1" href="https://twitter.com/atljewishfilm" target="_blank" rel="noreferrer"><IonIcon icon={logoTwitter} /></a>
                                    <a className="flex-grow-1" href="https://www.youtube.com/c/AtlantaJewishFilmFestival"target="_blank" rel="noreferrer"><IonIcon icon={logoYoutube} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-footer">
                    <div className="container">
                        <div className="row">
                            <h2 className="col-sm-12 col-md-2">Site Map</h2>
                            <div className="menus col-sm-12 col-md-10 d-flex">
                                <div className="menu-wrapper flex-grow-1">
                                    <Menu menuName="about-ajff" menu={this.props.menu} depth={1} />
                                </div>
                                <div className="menu-wrapper flex-grow-1">
                                    <Menu menuName="media" menu={this.props.menu} depth={1} />
                                </div>
                                <div className="menu-wrapper flex-grow-1">
                                    <Menu menuName="events" menu={this.props.menu} depth={1} />
                                </div>
                                <div className="menu-wrapper flex-grow-1">
                                    <Menu menuName="programs" menu={this.props.menu} depth={1} />
                                </div>
                                <div className="menu-wrapper flex-grow-1">
                                    <Menu menuName="support-ajff" menu={this.props.menu} depth={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="post-footer">
                    <div className="ajc">
                        <div className="container">
                            <div className="d-md-flex align-items-center">
                                <div className="logo">
                                    <Image src={AJCLogo} />                                
                                </div>
                                <div className="flex-grow-1">
                                    The Atlanta Jewish Film Festival is an independent non-profit arts organization that continues an active partnership with its founding agency, American Jewish Committee. Both organizations share a common commitment to strengthen Jewish life and foster intergroup understanding among Atlanta's diverse cultural, ethnic and religious populations.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="d-md-flex">
                            <div className="copyright flex-grow-1">
                                © {year} Copyright Atlanta Jewish Film Festival. All Rights Reserved. <a href="/content/privacy-policy">Privacy Policy</a>
                            </div>

                            <div className="signature flex-grow-1">
                                Site design by <a href="https://coolbluei.com" target="_blank" rel="noreferrer">Cool Blue Interactive</a> and <a href="https://jonnymaydesigns.com" target="_blank" rel="noreferrer">Jonny May</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}