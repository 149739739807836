import React, { Component } from 'react';
import '../../sidebar/SidebarItem/SidebarItem';
import SidebarItem from '../../sidebar/SidebarItem/SidebarItem';
import BuyButton from "../BuyButton/BuyButton";
import DateTimeFormated from "../../../ui/DateTimeFormated/DateTimeFormated";
import SpeakerList from '../../person/SpeakerList/SpeakerList';
import PersonMini from '../../person/PersonMini/PersonMini';
import Include from '../../../../drupal/Include';

export default class VirtualScreening extends Component {

    render = () => {

        const dateTime = (
            <DateTimeFormated format="dddd, MMMM D [at] LT" date={this.props.screening.get('field_start_date')} />
        );

        const button = (<BuyButton screening={this.props.screening} />);

        let speakers = [];
        let speakerEntities = [];

        const introSpeakers = this.props.screening.get('field_intro_speakers');
        if(introSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(introSpeakers);
        }

        const sponsorIntroSpeakers = this.props.screening.get('field_sponsor_intro_speakers');
        if(sponsorIntroSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(sponsorIntroSpeakers);
        }

        const qaModerators = this.props.screening.get('field_q_a_moderators');
        if(qaModerators instanceof Array) {
            speakerEntities = speakerEntities.concat(qaModerators);
        }

        const qaPanelists = this.props.screening.get('field_q_a_panelists');
        if(qaPanelists instanceof Array) {
            speakerEntities = speakerEntities.concat(qaPanelists);
        }

        for(const speaker of speakerEntities) {
            if(speaker instanceof Include && speaker.data) {
                speakers.push(<PersonMini name={speaker.get('title')} position={speaker.get('field_position')} />);
            }
        }

        let qaMessage = null;
        if(this.props.screening.get('field_has_q_a')) {
            qaMessage = (
                <div className="qa-message subtitle">
                    This screening includes a pre-recorded Q&A session.
                </div>
            );
        }

        let speakerList = null;
        if(speakers.length > 0) {
            speakerList = <SpeakerList speakers={speakers} />;
        }

        let extraContent = null;
        if(qaMessage || speakerList) {
            extraContent = (
                <div className="extra-content">
                    {qaMessage}
                    {speakerList}
                </div>
            );
        }

        return (
            <SidebarItem headline="Virtual Cinema" subtitle={dateTime} details={[this.props.screening.get('field_release_window_qualifier'), `Viewing Region: ${this.props.screening.get('field_geo_block')}`]} button={button} alternateTheme={true} extra={extraContent} />
        );
    }

}