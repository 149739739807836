import React, { Component } from 'react';
import HeroItem from '../HeroItem/HeroItem';

import './Hero.css';

export default class Hero extends Component {

    render() {

        return (
            <div className="hero container page-section padded">
                <HeroItem drupal={this.props.drupal} item={this.props.item} />
            </div>
        );
    }
}
