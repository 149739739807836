import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './SearchResult.css';

export default class SearchResult extends Component {

    render() {
        return (
            <div className="search-result row">

                <div className="col content">
                    <Link to={this.props.linkUrl}><h3 className="title">{this.props.title}</h3></Link>
                    <div className="teaser">{this.props.teaser}</div>
                </div>
            </div>
        );
    }

}
