import React, { Component } from 'react';

export default class LibSynEmbed extends Component {

    render = () => {
        return (
            <iframe allowfullscreen="" height="90" mozallowfullscreen="" msallowfullscreen="" oallowfullscreen="" scrolling="no" src={`//html5-player.libsyn.com/embed/episode/id/${this.props.libsynId}/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/1c17ae/`} style={{border: 'none'}} webkitallowfullscreen="" width="100%" title={this.props.libsynId}></iframe>
        );
    }

}