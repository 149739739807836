import React, { Component } from 'react';
import MetaItem from '../MetaItem/MetaItem';
import { Link } from 'react-router-dom';
import Include from '../../../../drupal/Include';

import './Meta.css';

export default class Meta extends Component {

    render() {
            let theme = 'standard';
            let rawValues = [];
            let values = [];
            let rawText = '';
            let text = '';
            let id = null;
            let i;

            if(this.props.alternateTheme) {
                theme = 'alternate';
            }

            if(this.props.isInclude) {
                if(this.props.isMulti && this.props.entity.get(this.props.field) instanceof Array) {
                    for(i in this.props.entity.get(this.props.field)) {
                        rawValues.push(this.props.entity.get(this.props.field)[i]);
                    }
                } else if(this.props.isMulti === false) {
                    rawValues.push(this.props.entity.get(this.props.field));
                }
            } else {
                let value = this.props.entity.get(this.props.field);

                if(typeof value == 'boolean') {
                    let tempValue = 'No';
                    if(value) {
                        tempValue = 'Yes';
                    }

                    value = tempValue;
                }

                if(this.props.isLink && value instanceof Object) {
                    value = (<a href={value.uri} target="_blank" rel="noreferrer">View Official Website</a>);
                }
                rawValues.push(value);
            }

            for(const include of rawValues) {
                if(include instanceof Include) {

                    if(include.data.attributes.hasOwnProperty('name')) {
                        rawText = include.get('name');
                    } else {
                        rawText = include.get('title');
                    }

                    if(this.props.suppressLinks) {
                        text = rawText;
                    } else {
                        text = <Link to={include.get('field_path')}>{rawText}</Link>;
                    }

                    id = include.data.id

                } else {
                    rawText = include;
                    if(rawText instanceof Object === false) {
                        text = rawText;
                    }
                }

                if(text) {
                    values.push(<MetaItem key={rawText} rawText={rawText} text={text} suffix={this.props.suffix} value={id} />);
                }
            }

            values.sort((a, b) => (a.props.rawText > b.props.rawText) ? 1 : -1);

            if(values.length > 0) {
                if(values.length === 1) {
                    return (
                        <div key={this.props.field} className={`meta ${theme}`}>
                            <span className="meta-label inline">{this.props.label}:</span>
                            <span className="meta-items inline">{values}</span>
                        </div>
                    );
                } else {
                    return (
                        <div key={this.props.field} className="meta">
                            <h5 className="meta-label">{this.props.label}</h5>
                            <div className="meta-items">{values}</div>
                        </div>
                    );
                }
            }

            return (
                <></>
            );

    }

    getEntityInfo = (entity) => {
        const typeParts = entity.type.split("--");
        const info = {
            entityType: typeParts[0],
            bundle: typeParts[1],
            id: entity.id
        };

        return info;
    }

}
