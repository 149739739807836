import React, { Component } from 'react';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import ItemCard from '../ItemList/ItemCard/ItemCard';
import Button from '../../../ui/Button/Button';
import Include from '../../../../drupal/Include';

import './RandomizedFilmList.css';

export default class RandomizedFilmList extends Component {

    state = {
        films: []
    };

    componentDidMount = () => {
        this.getRandomFilms();
    }

    render = () => {

        let items = [];

        let baseUrl = '//ajff.org';
        const outlet = this.props.item.get('field_outlet').get('name');
        if(outlet !== 'ajff.org') {
            baseUrl = `//${outlet}`;
        }

        for(const item of this.state.films) {
            const imageUrl = item.getImageUrlFromMedia('field_hero_image', 'ajff_org_small');
            items.push(<ItemCard title={item.get('title')} image={imageUrl} body={item.get('field_teaser')} linkUrl={`${baseUrl}${item.get('field_path')}`} linkText="Read more" />);
        }

        let colWidth = 4;
        if(this.state.films.length <= 2) {
            colWidth = 6;
        }

        let headline = null;
        let headlineText = this.props.item.get('field_headline');
        if(headlineText) {
            headline = (
                <h2 className="flex-grow-1 headline">{headlineText}</h2>
            );
        }

        let link = null;
        let linkObj = this.props.item.get('field_link');
        if(linkObj) {
            link = (
                <Button url={linkObj.url} text={linkObj.title} />
            );
        }

        return (
            <div className="item-list container page-section padded">
                <div className="d-md-flex align-items-center">
                    {headline}
                    <div className="link">
                        {link}
                    </div>
                </div>

                <ContentGrid small={12} medium={6} large={colWidth}>
                    {items}
                </ContentGrid>
            </div>
        )
    }

    getRandomFilms = async () => {
        let filmCount = 0;
    
        let options = {
          'page[limit]': 3,
          'filter[outlet][condition][path]': 'outlets.name',
          'filter[outlet][condition][value]': this.props.item.get('field_outlet').get('name')
        };

        if(this.props.item.get('field_taxonomy_term') instanceof Include) {
            options['filter[tag][path]'] = 'field_tags.id';
            options['filter[tag][value]'] = this.props.item.get('field_taxonomy_term').get('id');
        }
    
        const response = await this.props.api.getEntities('node', 'film', options);
        if (response.data.meta.count > 4) {
            filmCount = this.getRandomInt(response.data.meta.count - 3);
        }
        
        options['page[offset]'] = filmCount;
    
        const films = await this.props.api.getEntities('node', 'film', options);
        this.setState({
            films: films.data.data
        });
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

}