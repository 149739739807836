import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';

import './MapPopup.css';

export default class MapPopup extends Component {

    render() {
        if(this.props.content) {
            return (
                <div className="map-popup">
                    <div className="popup-close-trigger" ><IonIcon onClick={this.props.handleClose} icon={close} /></div>
                    {this.props.content}
                </div>
            );
        }

        return (
            <></>
        );
    }

}