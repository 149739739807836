import React, { Component } from 'react';
import Include from '../../../../drupal/Include';
import { Image } from 'react-bootstrap';
import Button from '../../../ui/Button/Button';
import Text from '../../../atoms/Text/Text';

import './FilmMini.css';

export default class FilmMini extends Component {

    render = () => {

        let image = null;
        const imageInclude = this.props.film.get('field_hero_image');
        let imageUrl = null;
        if(imageInclude instanceof Include) {
            imageUrl = imageInclude.get('field_media_image').get('links')?.ajff_org_small?.href;
            image = (
                <div className="image col col-md-4">
                    <Image src={imageUrl} />
                </div>
            );
        }

        return (
            <div className="film-mini">
                <h4>{this.props.film.get('title')}</h4>
                <div className="row">
                    {image}
                    <div className="details col col-md-8">
                        <div className="synopsis">
                            <h5>About the Film</h5>
                            <Text text={this.props.film.get('field_synopsis')} />
                        </div>
                        <div className="link">
                            <Button url={this.props.film.get('field_path')} text="View Details" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}