import React, { Component } from "react";

export default class FilterOption extends Component {

    render = () => {

        return(
            <option value={this.props.value} selected={this.props.selected}>
                {this.props.label}
            </option>
        );

    }

}