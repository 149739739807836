import React, { Component } from 'react';
import Text from "../../atoms/Text/Text";

import './SubscribeForm.css';

export default class SubscribeForm extends Component {

    render = () => {
      const html = (
          `<!-- Begin Mailchimp Signup Form -->
          <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
            <style type="text/css"> #mc_embed_signup {clear: left; font: 14px Helvetica, Arial, sans-serif; width: 100%;}
              /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */</style>
            <div id="mc_embed_signup">
            <div>Sign up to receive AJFF Updates, Ticketing Announcements, and more.</div>
              <form action="https://ajff.us4.list-manage.com/subscribe/post?u=241b742704319db2320f27bee&amp;id=84e1f531e4"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
                    target="_blank" novalidate>
                <div id="mc_embed_signup_scroll"><input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL"
                                                        placeholder="email address" required>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                                                                                            name="b_241b742704319db2320f27bee_84e1f531e4"
                                                                                            tabindex="-1" value=""></div>
                  <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                                            class="button"></div>
                </div>
              </form>
            </div><!--End mc_embed_signup-->`
      );

      return ( 
        <div className="subscribe-form">
          <Text text={html} />
        </div>
      );
    }

}