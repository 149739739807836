import React, { Component } from "react";
import TextPlaceholder from "../../../placeholders/TextPlaceholder";
import SingleLineTextPlaceholder from "../../../placeholders/SingleLineTextPlaceholder";
import ImageVideoPlaceholder from "../../../placeholders/ImageVideoPlaceholder";
import SidebarListPlaceholder from "../../sidebar/SidebarListPlaceholder";

export default class OfferingCardPlaceholder extends Component {

    render = () => {

        return (
            <div className="offering-card-placeholder padded">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <ImageVideoPlaceholder />
                        <SingleLineTextPlaceholder size="h3" />
                        <TextPlaceholder />
                    </div>
                    <div className="col-12 col-md-6">
                        <SidebarListPlaceholder />
                    </div>
                </div>
            </div>
        );

    }

}