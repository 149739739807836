import React, { Component } from 'react';
import SponsorCard from '../../sponsor/SponsorCard/SponsorCard';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import Accordion from '../../../ui/Accordion/Accordion';
import { IonIcon } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';

import './SponsorList.css';

export default class SponsorList extends Component {

    render = () => {
        let sponsors = [];

        for(const sponsor of this.props.sponsors) {
            if(sponsor.data) {
                sponsors.push(<SponsorCard sponsor={sponsor} />);
            }
        }

        let colWidth = 3;
        if(this.props.sponsors.length <= 3) {
            colWidth = 4;
        }

        let classes = 'container';
        if(this.props.alternateTheme) {
            classes = null;
        }

        let headline = null;
        if(this.props.headline && sponsors.length > 0) {
            headline = (
                <h2>{this.props.headline}</h2>
            );

            if(this.props.alternateTheme) {
                headline = (
                    <h5>{this.props.headline}</h5>
                );
            }
        }

        let content = (
            <ContentGrid small={4} medium={4} large={colWidth}>
                {sponsors}
            </ContentGrid>
        );

        const expandedIcon = <IonIcon icon={removeOutline} />;
        const collapsedIcon = <IonIcon icon={addOutline} />;

        if(this.props.collapsible) {
            content = (
                <Accordion collapsed={this.props.collapsed} collapsedText="View Sponsors" expandedText="Hide Sponsors" collapsedIcon={collapsedIcon} expandedIcon={expandedIcon}>
                    {content}
                </Accordion>
            );         
        }

        return (
            <div className={`sponsor-list ${classes}`}>
                {headline}

                {content}
            </div>
        );
    }

}