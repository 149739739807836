import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Button from "../../../../ui/Button/Button";

import './PressKitCard.css';
import '../../../paragraphs/ItemList/ItemCard/ItemCard.css';

export default class PressKitCard extends Component {

    render = () => {

        let image = null;
        const imageUrl = this.props.entity.get('field_film').get('field_hero_image').get('field_media_image').get('links').ajff_org_small?.href;
        if(imageUrl) {
            image = <Image src={imageUrl} />
        }

        const title = this.props.entity.get('name');

        let websiteLink = null;
        const website = this.props.entity.get('field_film').get('field_website')?.url;
        if(website) {
            websiteLink = <Button url={website} text="Official Website" />;
        }


        return(
            <div className="press-kit-card item-card">
                {image}
                <h4 className="title">{title}</h4>

                {websiteLink}

                <Button url={this.props.entity.get('field_media_link')} text="Download Press Kit" />
            </div>
        );

    }

}