import React, { Component } from "react";
import HeroItemPlaceholder from "../../components/content/paragraphs/HeroItem/HeroItemPlaceholder";
import ItemListPlaceholder from "../../components/content/paragraphs/ItemList/ItemListPlaceholder";
import SingleLineTextPlaceholder from "../../components/placeholders/SingleLineTextPlaceholder";


export default class PagePlaceholder extends Component {

    render = () => {

        return (
            <div className="page-placeholder container">
                <SingleLineTextPlaceholder size="h1" />
                
                <HeroItemPlaceholder />

                <ItemListPlaceholder />

            </div>
        );

    }

}