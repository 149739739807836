import React, { Component } from "react";

import './placeholder.css';

export default class TextPlaceholder extends Component {

    render = () => {

        return (
            <div className="placeholder">
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
            </div>
        );
    }

}