import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Meta from '../../components/atoms/Meta/Meta';
import Text from "../../components/atoms/Text/Text";
import {Image} from "react-bootstrap";
import Address from "../../components/atoms/Address/Address";
import GoogleMap from "../../components/ui/Map/GoogleMap/GoogleMap";

import "./Venue.css";

export default class VenuePage extends Component {

  render() {

    let description = null;
    let address = null;
    let parkingDetails = null;
    let logo = null;
    let hero = null;
    let map = null;
    let meta = null;


    if(this.props.entity instanceof Entity) {
      // Hero.
      const mainImageUrl = this.props.entity.getImageUrlFromMedia('field_hero_image', 'ajff_org_large_no_crop');
      if(mainImageUrl) {
        hero = <Image onClick={this.openModal} src={mainImageUrl} />;
      }
      // Logo
      const logoUrl = this.props.entity.getImageUrlFromMedia('field_logo', 'ajff_org_small')
      if (logoUrl) {
        logo = (
            <div class="logo">
              <Image src={logoUrl}/>
            </div>
        );
      }

      if(this.props.entity.get('field_description')) {
        // Description
        description = <Text text={this.props.entity.get('field_description').processed} />;
      }

      const addressField = this.props.entity.get('field_address');
      if(addressField) {
        // Address
        address = (
          <div className="page-section">
            <h5>Address</h5>
            <Address addressField={addressField} />
          </div>
        );

        // @todo Google map data!!!!
      }

      const parkingField = this.props.entity.get('field_parking_details')
      if(parkingField) {
        // Parking Details
        parkingDetails = (
          <div className="page-section">
            <h5>Parking Details</h5>
            <Text text={parkingField.processed} />
          </div>
        );
      }
      // Entity.get() returns empty data array.  Have to get it the hard way.
      const geoField = this.props.entity.data.data.attributes.field_geolocation;
      if (geoField) {
        let places = [];
        places.push (
            {
              id: this.props.entity.get('id') + '-geofield',
              lat: geoField.lat,
              lng: geoField.lng,
              hideLabel: false,
              name: this.props.entity.get('title'),
              type: 'parking',
              popupContent: null,
            }
        );
        map = (<GoogleMap places={places} defaultZoom={16} outlet={this.props.outlet} />);
      }

      meta = <Meta data={this.props.entity.get('meta')} />
    }

    return (
      <div className="page venue-details">
        {meta}

        <div className="content-wrapper container-fluid full-width">
          <div className="row">
            <div className="col">
              <div className="hero container page-section">
                {hero}
              </div>
              <div className="page-title page-section padded container">
                <h1>{this.props.entity.get('title')}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="page-content col-12">
              <div className="hero container page-section">
                <div className="details">
                  {logo}
                  {description}
                </div>
                {address}
                {parkingDetails}
                {map}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}