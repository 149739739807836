import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import Meta from '../../components/atoms/Meta/Meta';
import DateTimeFormated from "../../components/ui/DateTimeFormated/DateTimeFormated";

import './ArticlePage.css';
export default class ArticlePage extends Component {

  render() {

    let hero = null;
    let title = null;
    let content = [];

    const dateTime = (
        <div className="row">
          <div className="col">
            <div className="publish-date container">
              <DateTimeFormated format="MM/D/YYYY" date={this.props.entity.get('field_display_date')} />
            </div>
          </div>
        </div>
    );

    if(this.props.entity instanceof Entity) {
      if(this.props.entity.get('field_heroes') instanceof Array) {
        hero = (
            <div className="row">
              <div className="col">
                <Paragraph content={this.props.entity.get('field_heroes')} drupal={this.props.drupal} />
              </div>
            </div>
        );
      }
      if (!this.props.entity.get('field_hide_title')){
        title = (
            <div className="row">
              <div className="col">
                <div className="page-title page-section padded container">
                  <h1>{this.props.entity.get('title')}</h1>
                </div>
              </div>
            </div>
        );
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        content.push(<Paragraph content={paragraph} api={this.props.api} drupal={this.props.drupal} />);
      }
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
        <div className="article page">
          {meta}

          <div className="content-wrapper container-fluid full-width">
            {title}
            {dateTime}
            {hero}

            <div className="row">
              <div className="page-content col-12">
                {content}
              </div>
            </div>

          </div>
        </div>
    );
  }

}