import React, { Component } from 'react';
import Spotlight from '../Spotlight/Spotlight';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

import './SpotlightList.css';

export default class SpotlightList extends Component {

    render = () => {
        let items = [];

        for(const item of this.props.items) {
            items.push(<Spotlight spotlight={item} />)
        }

        let headline = null;
        if(this.props.headline) {
            headline = (
                <h2>{this.props.headline}</h2>
            );
        }

        return (
            <div className="spotlight-list page-section">
                <div className="container">
                    {headline}

                    <ContentGrid small={12} medium={6} large={3}>
                        {items}
                    </ContentGrid>
                </div>
            </div>
        )
    }

}