import React, { Component } from 'react';

export default class VimeoEmbed extends Component {

    render() {
        return (
            <div className="vimeo-embed video-embed">
                <iframe src={`https://player.vimeo.com/video/${this.props.videoId}?h=b5a703a505&app_id=122963`} title={this.props.videoId} autoplay frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        );
    }

}
