import React, { Component } from 'react';
import Title from '../../components/atoms/Title/Title';
import Meta from '../../components/atoms/Meta/Meta';
import Entity from '../../drupal/Entity';
import Include from '../../drupal/Include';
import { Image } from 'react-bootstrap';
import Modal from '../../components/ui/Modal/Modal';
import RemoteVideo from '../../components/content/media/RemoteVideo/RemoteVideo';
import ScreeningList from '../../components/content/happenings/ScreeningList/ScreeningList';
import QAList from '../../components/content/media/QA/QAList/QAList';
import FilmDetails from '../../components/content/film/FilmDetails/FilmDetails';
import OfferingDetails from '../../components/content/offering/OfferingDetails/OfferingDetails';
import MediaList from '../../components/content/media/MediaList/MediaList';
import { IonIcon } from '@ionic/react';
import { playCircle } from 'ionicons/icons';
import SponsorList from '../../components/content/paragraphs/SponsorList/SponsorList';

import './FilmOfferingDetailPage.css';

export default class FilmOfferingDetailPage extends Component {

    state = {
        modalOpen: false,
        modalContent: null,
        trailer: null
    };

    componentDidMount = () => {
        if(this.props.entity.get('type') === 'node--film') {
            const trailer = this.props.entity.get('field_media').find(element => element.get('field_media_type') instanceof Include && element.get('field_media_type').data instanceof Object && element.get('field_media_type').get('name') === 'Trailer');
            this.setState({
                trailer: trailer
            });
    
        }
        
    }

    render() {
        let title = null;
        let isFilm = true;

        if(this.props.entity instanceof Entity) {
            title = <Title text={this.props.entity.get('title')} />;
        }

        let mainImage = null;
        const heroImageInclude = this.props.entity.get('field_hero_image');
        let mainImageUrl = null;
        if(heroImageInclude instanceof Include) {
            mainImageUrl = heroImageInclude.get('field_media_image').get('links')?.large?.href;
        }

        if(mainImageUrl) {
            mainImage = (
                <div className="main-image">
                    <Image onClick={this.openModal} src={mainImageUrl} />
                </div>
            );

            if(this.state.trailer) {
                mainImage = (
                    <div className="main-image has-trailer" role="button">
                        {mainImage}
                        <div onClick={this.openModal} className="trailer-indicator"><IonIcon icon={playCircle} /> Watch Trailer</div>
                    </div>
                );
            }
        }

        let details = null;
        switch(this.props.entity.get('type')) {
            case 'node--film':
                details = <FilmDetails entity={this.props.entity} />
                break;
            case 'node--offering':
                details = <OfferingDetails entity={this.props.entity} />
                isFilm = false;
                break;
            default:
                details = null;
        }

        let sponsors = null;
        if(this.props.entity.get('field_sponsors') instanceof Array) {
            sponsors = <SponsorList sponsors={this.props.entity.get('field_sponsors')} headline="Sponsors" alternateTheme={true} />;
        }

        const metadata = <Meta data={this.props.entity.get('meta')} />

        return (
            <div className="content-wrapper film-offering-detail-page">
                {metadata}

                <div className="container page-content">

                    {title}

                    <div className="row">

                        <div className="col-sm-12 col-md-7 film-content">

                            {mainImage}

                            {details}

                            {sponsors}

                        </div>

                        <div className="col-sm-12 col-md-5 sidebar">
                            <ScreeningList api={this.props.api} contentId={this.props.entity.get('id')} isFilm={isFilm} showTitle={true} isPageSection={true} />
                            <QAList api={this.props.api} contentId={this.props.entity.get('id')} isFilm={isFilm} />
                            <MediaList entity={this.props.entity} />
                        </div>


                    </div>

                </div>

                {this.state.modalContent}

            </div>
        );
    }
    
    openModal = () => {

        if(this.state.trailer instanceof Object) {
            const modal = (
                <Modal modalType='video' handleClose={this.closeModal}>
                    <RemoteVideo url={this.state.trailer.get('field_media_oembed_video')} />
                </Modal>
            );

            this.setState({
                modalOpen: true,
                modalContent: modal,
            });
        }
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            modalContent: null
        });
    }
}
