import { Component } from 'react';
import parse from 'html-react-parser';

export default class EmbeddedMedia extends Component {

    render = () => {
        let content = parse(this.props.content, {
            replace: domNode => {
                if(domNode.type === 'script') {
                    var script = document.createElement('script');
                    script.src = domNode.attribs.src;
                    document.head.appendChild(script);
                }
            }
        });

        return (
            <div className="embedded-media container page-section padded">
                { content }
            </div>
        );
    }

}