import React, { Component } from 'react';
import ItemCard from '../ItemList/ItemCard/ItemCard';

export default class ContentItem extends Component {

    render = () => {

        let imageUrl = null;
        let body = this.props.item[0].get('field_teaser');
        let media = null;
        let suppressLink = false;

        switch(this.props.item[0].get('type')) {


            case 'node--page':
            case 'node--festival_series':
                imageUrl = this.props.item[0].get('field_heroes')[0].get('field_hero_image').get('field_media_image').get('links').ajff_org_small?.href;
                break;

            case 'node--person':
                body = this.props.item[0].get('field_position');
                suppressLink = true;
            // eslint-disable-next-line
            case 'node--article':
            // eslint-disable-next-line
            case 'node--film':
            // eslint-disable-next-line
            case 'node--venue':
            // eslint-disable-next-line
            default:
                media = this.props.item[0].get('field_hero_image');
                if (media) {
                    imageUrl = media.get('field_media_image').get('links').ajff_org_small?.href;
                }
                break;
        }

        return (
            <div className="content-item">
                <ItemCard title={this.props.item[0].get('title')} image={imageUrl} body={body} linkUrl={this.props.item[0].get('field_path')} linkText="Read more" suppressLink={suppressLink} />
            </div>
        );
    }

}