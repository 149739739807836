import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationPin from '../LocationPin/LocationPin';

import './GoogleMap.css';

export default class GoogleMap extends Component {
    
    render() {
        const places = this.props.places;
        const styles = this.getStyle();
        const apiKey = this.props.outlet.get('field_variables').find((e) => e.key === "google_maps_api_key")?.value;
        
        let lats = [];
        let lngs = [];

        let pins = [];
        for(let place of places) {
            pins.push(<LocationPin key={place.id} lat={place.lat} lng={place.lng} hideLabel={place.hideLabel} text={place.name} type={place.type} popupContent={place.popupContent} />);
            lats.push(place.lat);
            lngs.push(place.lng);
        }

        const center = {
            lat: this.avg(lats),
            lng: this.avg(lngs)
        };

        // Google hides the control positioning constants for the zoom controls. They are:
        // TOP_LEFT: 1,
        // TOP_CENTER: 2,
        // TOP: 2,
        // TOP_RIGHT: 3,
        // LEFT_CENTER: 4,
        // LEFT_TOP: 5,
        // LEFT: 5,
        // LEFT_BOTTOM: 6,
        // RIGHT_TOP: 7,
        // RIGHT: 7,
        // RIGHT_CENTER: 8,
        // RIGHT_BOTTOM: 9,
        // BOTTOM_LEFT: 10,
        // BOTTOM_CENTER: 11,
        // BOTTOM: 11,
        // BOTTOM_RIGHT: 12,
        // CENTER: 13

        const options = {
            styles: styles,
            zoomControlOptions: {
              position: 5
            }
        };

        return (
            <div className="google-map">

                <GoogleMapReact bootstrapURLKeys={{key: apiKey}} defaultCenter={center} defaultZoom={this.props.defaultZoom} options={options}>
                    {pins}
                </GoogleMapReact>

            </div>
        );
    }

    buildLocationPins = (place) => {
        const location = <LocationPin lat={place.lat} lng={place.lng} hideLabel={place.hideLabel} text={place.name} type={place.type} />

        return location;
    }

    getStyle = () => {
        return [
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "visibility": "on"
              },
              {
                "weight": 5
              }
            ]
          },
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "lightness": 65
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "poi.government",
            "stylers": [
              {
                "saturation": 70
              }
            ]
          },
          {
            "featureType": "poi.park",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "saturation": 30
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "water",
            "stylers": [
              {
                "saturation": 75
              },
              {
                "lightness": -10
              },
              {
                "visibility": "simplified"
              }
            ]
          }
        ];
    }

    avg = (values) => {
        let avg = 0;
        let total = 0;

        if(values.length > 0) {
            for(let value of values) {
                total += value;
            }
    
            avg = total / values.length;
        }

        return avg;
    }
}