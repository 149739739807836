import React, { Component } from "react";

import './AlertBox.css';

export default class AlertBox extends Component {

    render = () => {

        return(
            <div className="alert-box">
                {this.props.children}
            </div>
        );

    }

}